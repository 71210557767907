import { device } from "theme/breakpoints";

import { useEffect, useState } from "react";

export const breakPoints = device;

const useMediaQuery = (query: "mobile" | "tablet" | "laptop") => {
  const [matches, setMatches] = useState<boolean>(false);
  useEffect(() => {
    const media = window.matchMedia(breakPoints[query]);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};

export default useMediaQuery;

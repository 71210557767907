import styled from "styled-components";
import * as Icons from "theme/icons";

import React, { Fragment, forwardRef } from "react";

const MISSING_ICON_ID = "missing-icon";
export type IconName = keyof typeof Icons;

const Picture = styled.picture`
  flex-shrink: 0;
  aspect-ratio: 1 / 1;

  svg {
    aspect-ratio: 1 / 1;
  }
`;

interface Props
  extends Omit<React.HTMLAttributes<HTMLPictureElement>, "data-testid"> {
  name: IconName;
  svgProps?: React.SVGProps<SVGSVGElement>;
}

// eslint-disable-next-line react/display-name
const Icon = forwardRef<HTMLPictureElement, Props>(
  ({ name, svgProps, ...props }, ref) => {
    const icon = (Icons as Record<IconName, React.FC | undefined>)[name];

    return (
      <Picture ref={ref} data-testid={icon ? name : MISSING_ICON_ID} {...props}>
        {icon ? React.createElement(icon, svgProps) : <Fragment />}
      </Picture>
    );
  }
);

export default Icon;
